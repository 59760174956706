@font-face {
  font-family: SuisseIntl;
  src: url("fonts/SuisseIntl-Regular.otf") format("opentype"); }

@font-face {
  font-family: SuisseIntl;
  font-weight: bold;
  src: url("fonts/SuisseIntl-Bold.otf") format("opentype"); }

@font-face {
  font-family: SuisseIntl;
  font-weight: 500;
  src: url("fonts/SuisseIntl-Medium.otf") format("opentype"); }

/** Responsive **/
nav {
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  width: 100%;
  z-index: 100; }
  nav.smaller {
    background-color: #222222; }
    nav.smaller .menu a {
      transition-property: padding, font;
      transition-duration: .5s;
      transition-timing-function: ease-in-out;
      padding: calc(1.5rem - 10px) 1rem 1.5rem;
      font-size: 80%;
      border-top-width: 8px; }
  nav .logo {
    padding: 0 2rem; }
    @media (max-width: 599px) {
      nav .logo img {
        width: 42px;
        height: 42px; } }
  nav .menu {
    margin: 0;
    display: flex;
    flex-flow: row nowrap; }
    nav .menu a {
      transition-property: padding, font;
      transition-duration: .5s;
      transition-timing-function: ease-in-out;
      position: relative;
      display: block;
      margin: 0 2rem;
      padding: calc(2.5rem - 10px) 1rem 2.5rem;
      text-transform: uppercase;
      letter-spacing: .2rem;
      color: #ffffff;
      border-top: 10px solid transparent; }
      nav .menu a:after {
        content: '';
        display: block;
        position: absolute; }
    nav .menu > a:after, nav .menu:hover > a.active:not(:hover):after {
      -webkit-transform-origin: right;
      transform-origin: right;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      top: -10px;
      left: 0;
      height: 10px;
      width: 100%;
      background-color: #1de9b6;
      -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.55, 0, 0.1, 1);
      transition: -webkit-transform 0.6s cubic-bezier(0.55, 0, 0.1, 1);
      transition: transform 0.6s cubic-bezier(0.55, 0, 0.1, 1); }
    nav .menu a:hover:after, nav .menu a.active:after {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      -webkit-transform-origin: left;
      transform-origin: left;
      -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.55, 0, 0.1, 1);
      transition: -webkit-transform 0.6s cubic-bezier(0.55, 0, 0.1, 1);
      transition: transform 0.6s cubic-bezier(0.55, 0, 0.1, 1); }

main {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 100%;
  overflow: hidden;
  align-items: end;
  padding-bottom: 25vh;
  background: url("img/main-image-1.png") no-repeat top right, url("img/main-image-2.png") no-repeat bottom left;
  background-size: calc(7 / 12 * 100vw), calc(3 / 12 * 100vw); }
  main h1.page-heading {
    margin: 0;
    grid-column: 2/8; }
    @media (min-width: 1100px) {
      main h1.page-heading {
        grid-column: 3/9; } }
  main .main-action {
    grid-column: 8/12;
    justify-self: end; }
    @media (min-width: 1100px) {
      main .main-action {
        grid-column: 9/12; } }

.portfolio {
  position: relative; }
  .portfolio .page-content {
    display: flex;
    flex-flow: row wrap; }
    .portfolio .page-content .category-menu {
      width: calc(100% / 12 * 3);
      padding-left: 0;
      position: absolute;
      margin-top: 6rem; }
      .portfolio .page-content .category-menu.fixed {
        position: fixed;
        top: calc(50vh - 200px); }
      .portfolio .page-content .category-menu a {
        display: block;
        margin: 0;
        padding: 1rem 1rem 1rem calc(100% / 6);
        color: rgba(255, 255, 255, 0.6); }
        .portfolio .page-content .category-menu a:first-child {
          margin-top: 0; }
        .portfolio .page-content .category-menu a:hover, .portfolio .page-content .category-menu a.active {
          color: white;
          transition-duration: .5s;
          transition-timing-function: ease-in-out; }
        .portfolio .page-content .category-menu a.active:nth-child(1) ~ .active-highlighter {
          top: 0.5rem; }
        .portfolio .page-content .category-menu a:hover:nth-child(1) ~ .active-highlighter {
          top: 0.5rem !important; }
        .portfolio .page-content .category-menu a.active:nth-child(2) ~ .active-highlighter {
          top: 4rem; }
        .portfolio .page-content .category-menu a:hover:nth-child(2) ~ .active-highlighter {
          top: 4rem !important; }
        .portfolio .page-content .category-menu a.active:nth-child(3) ~ .active-highlighter {
          top: 7.5rem; }
        .portfolio .page-content .category-menu a:hover:nth-child(3) ~ .active-highlighter {
          top: 7.5rem !important; }
        .portfolio .page-content .category-menu a.active:nth-child(4) ~ .active-highlighter {
          top: 11rem; }
        .portfolio .page-content .category-menu a:hover:nth-child(4) ~ .active-highlighter {
          top: 11rem !important; }
        .portfolio .page-content .category-menu a.active:nth-child(5) ~ .active-highlighter {
          top: 14.5rem; }
        .portfolio .page-content .category-menu a:hover:nth-child(5) ~ .active-highlighter {
          top: 14.5rem !important; }
      .portfolio .page-content .category-menu .active-highlighter {
        position: absolute;
        width: 10px;
        height: 2.5rem;
        background-color: #1de9b6;
        transition: .3s ease-in-out; }
    .portfolio .page-content .works {
      margin-left: calc(100% / 12 * 3);
      width: calc(100% / 12 * 8); }
      .portfolio .page-content .works .work {
        margin: calc(100vw / 12) 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: center; }
        .portfolio .page-content .works .work:first-child {
          margin-top: 6rem; }
        .portfolio .page-content .works .work .title {
          width: 50%;
          margin: 0;
          padding-right: calc(100vw / 12 - 1rem);
          z-index: 50; }
          .portfolio .page-content .works .work .title h2 {
            grid-area: title; }
          .portfolio .page-content .works .work .title .action-link {
            margin-left: 0; }
        .portfolio .page-content .works .work .preview {
          background-color: #4a4a4a;
          width: 50%;
          padding-bottom: calc(6.5 / 4 * 50%);
          position: relative; }
          .portfolio .page-content .works .work .preview img, .portfolio .page-content .works .work .preview .img-preloader {
            position: absolute;
            width: 112.5%;
            height: calc(100% * 4.5 / 6.5);
            right: 25%;
            top: calc(100vw / 12); }
          .portfolio .page-content .works .work .preview img {
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            transition-duration: .5s;
            transition-timing-function: ease-in-out; }
            .portfolio .page-content .works .work .preview img.mouseover {
              transition-duration: .5s;
              transition-timing-function: ease-in-out;
              -webkit-filter: grayscale(0%);
              filter: grayscale(0%);
              transform: scale(1.05); }
          .portfolio .page-content .works .work .preview .img-preloader {
            display: flex;
            align-items: center;
            justify-content: space-around; }
        .portfolio .page-content .works .work:nth-child(even) {
          flex-direction: row-reverse; }
          .portfolio .page-content .works .work:nth-child(even) .title {
            padding-left: 3rem; }
          .portfolio .page-content .works .work:nth-child(even) .action-link {
            float: right; }
          .portfolio .page-content .works .work:nth-child(even) .preview img, .portfolio .page-content .works .work:nth-child(even) .preview .img-preloader {
            right: -37.5%; }
  @media (min-width: 1400px) {
    .portfolio {
      padding-top: 150px; } }

.work-page {
  margin: calc(100vw / 12);
  width: calc(100vw * 10 / 12); }
  .work-page > h2 {
    text-transform: uppercase;
    width: 50%; }
  .work-page .case-content {
    display: flex;
    flex-flow: column; }
    .work-page .case-content > a {
      margin-bottom: -6px; }
    .work-page .case-content > img, .work-page .case-content > a > img {
      width: 100%;
      transition-duration: .5s;
      transition-timing-function: ease-in-out; }
  .work-page.overlayed {
    position: absolute;
    z-index: 1000; }
  @media (min-width: 1100px) {
    .work-page {
      margin-left: calc(100vw / 6);
      margin-right: calc(100vw / 6);
      width: calc(100vw * 8 / 12); } }

.embed-container {
  position: relative;
  overflow: hidden; }

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.contacts .links {
  margin-left: calc(100% / 6);
  padding: 0; }
  .contacts .links li {
    display: inline-block;
    margin: 0 3rem 0 0; }

.contacts .about {
  margin: calc(100% / 12) 0;
  display: flex;
  flex-flow: row nowrap; }
  .contacts .about .photo {
    margin: calc(100% / 12);
    width: calc(100% / 3);
    height: calc(100% / 3);
    z-index: 50; }
    .contacts .about .photo img {
      width: 100%;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      transition-duration: .5s;
      transition-timing-function: ease-in-out; }
      .contacts .about .photo img:hover {
        transition-duration: .5s;
        transition-timing-function: ease-in-out;
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);
        transform: scale(1.05); }
  .contacts .about .preloader {
    margin: calc(50% * 11 / 12 - .1rem) 0; }
  .contacts .about .description {
    background-color: #4a4a4a;
    width: calc(100% * 7 / 12);
    margin-left: calc(-100% / 6);
    padding: calc(100% / 12);
    padding-left: calc(100% / 6); }

.contacts .cv {
  margin: 8rem calc(100% / 12) 4rem; }
  .contacts .cv .cv-title {
    width: calc(100% / 4); }
  .contacts .cv .experience {
    width: 100%;
    border-spacing: 0; }
    .contacts .cv .experience .experience-item td {
      border-bottom: 1px solid #1de9b6;
      padding: 2rem 0; }
      .contacts .cv .experience .experience-item td.years, .contacts .cv .experience .experience-item td.type {
        width: 20%; }
      .contacts .cv .experience .experience-item td.type {
        text-align: right; }
    .contacts .cv .experience .experience-item:first-child td {
      border-top: 1px solid #1de9b6; }

.contacts .see-my-works {
  margin: calc(100% / 12) 0;
  text-align: center; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html, #root, body {
  height: 100%; }

body {
  margin: 0;
  background-color: #222222;
  font-family: SuisseIntl, sans-serif;
  line-height: 1.5rem;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a, .underline-hover {
  color: #1de9b6;
  text-decoration: none;
  display: inline-block;
  cursor: pointer; }

.underline-hover:after {
  content: '';
  width: 0;
  height: 1px;
  display: block;
  background: #1de9b6;
  transition: 300ms; }

.underline-hover:not(.action-link):hover:after {
  width: 100%; }

.close-btn {
  position: fixed;
  right: calc(100vw / 24);
  top: calc(100vw / 24);
  background: url("img/icon-close.svg") no-repeat center right;
  padding-right: 30px;
  color: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 2000; }

h1 {
  font-size: 2rem;
  line-height: 0.9;
  letter-spacing: 2px;
  color: #1de9b6; }
  @media (min-width: 600px) {
    h1 {
      font-size: 3.5rem;
      letter-spacing: .25rem; } }
  @media (min-width: 1100px) {
    h1 {
      font-size: 4.75rem; } }
  @media (min-width: 1400px) {
    h1 {
      font-size: 6rem; } }
  h1.page-heading {
    margin: 4rem 0 2rem calc(100vw / 6);
    text-transform: uppercase; }
    h1.page-heading .transparent {
      color: rgba(34, 34, 34, 0);
      -webkit-text-stroke: 2px #ffffff; }
    @media (min-width: 1100px) {
      h1.page-heading {
        margin-top: 14rem; } }

h2 {
  font-size: 3rem;
  line-height: 0.92;
  letter-spacing: 3px;
  color: #1de9b6; }

h3 {
  font-size: 34px;
  font-weight: bold;
  line-height: 0.88;
  letter-spacing: 3px;
  color: #1de9b6; }

.action-link {
  display: inline-block;
  background: url("img/action-arrow.svg") no-repeat no-repeat right center;
  margin: 0 1rem;
  padding: 0 120px 0 0;
  text-transform: uppercase;
  line-height: 70px;
  letter-spacing: .2rem;
  white-space: nowrap;
  color: #ffffff; }
  @media (min-width: 1400px) {
    .action-link {
      font-size: 1.125rem; } }

li {
  list-style: none; }

.content {
  padding-top: 100px;
  padding-bottom: 15vh; }

.category-name {
  text-transform: uppercase;
  font-size: .8em;
  letter-spacing: 2px; }

.block-heading {
  margin: 1rem 0 2rem;
  color: #1de9b6;
  font-size: 2rem;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 2px;
  font-weight: bold; }

.preloader {
  text-align: center; }
  .preloader div {
    width: .5rem;
    height: .5rem;
    margin-left: .75rem;
    display: inline-block;
    background-color: #1de9b6; }
    .preloader div:nth-child(1) {
      animation: focus 1s 0.33s linear infinite; }
    .preloader div:nth-child(2) {
      animation: focus 1s .67s linear infinite; }
    .preloader div:nth-child(3) {
      animation: focus 1s 1s linear infinite; }

@keyframes focus {
  0% {
    transform: scale(1);
    opacity: 0; }
  50% {
    transform: scale(1.5);
    opacity: 0.5; }
  75% {
    transform: scale(1.2);
    opacity: 0.7; }
  90% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0);
    opacity: 0; } }

.loading {
  opacity: 0; }

.noscroll {
  overflow: hidden; }

.scrollable-overlay {
  background: rgba(34, 34, 34, 0.92);
  position: fixed;
  overflow-y: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000; }

.full-screen-preloader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw; }

.work-title {
  margin: 1rem 0 3rem;
  text-transform: uppercase; }
